import { CognitoUser } from '@aws-amplify/auth';
import { HubCallback } from '@aws-amplify/core';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import Amplify, { Auth, Hub } from 'aws-amplify';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import Api from '../api';
import { LegalEventType } from '../api/legal-event';
import { AuthContext, AuthState, AuthStatus, BuysideTosStatus, signOut, UserAttributes } from '../context/auth';
import { AppConfig, AppConfigType } from '../model/appConfig';
import { ChildrenProps } from '../types/common';
import { StandardCognitoAttributes } from '../util/cognito';

interface AmplifyConfig {
  userPoolId: string;
  userPoolWebClientId: string;
  domain: string;
  redirectSignIn: string;
  redirectSignOut: string;
}

interface AuthContainerProps {
  amplifyConfig: AmplifyConfig;
  appConfig: AppConfig;
}

export const AuthContainer = ({ amplifyConfig, children, appConfig }: ChildrenProps<AuthContainerProps>) => {
  const [status, setStatus] = useState<AuthStatus>(AuthStatus.INITIAL);
  const [buysideTosStatus, setBuysideTosStatus] = useState<BuysideTosStatus>(BuysideTosStatus.INITIAL);
  const [user, setUser] = useState<CognitoUser | undefined>(undefined);
  const [userAttributes, setUserAttributes] = useState<UserAttributes | undefined>(undefined);

  useEffect(() => {
    const { userPoolId, userPoolWebClientId, domain, redirectSignIn, redirectSignOut } = amplifyConfig;
    // FIXME default Amplify import is deprecated
    Amplify.configure({
      Auth: {
        userPoolId,
        userPoolWebClientId,
        oauth: {
          domain,
          scope: ['profile', 'openid'],
          redirectSignIn,
          redirectSignOut,
          responseType: 'code',
        },
      },
    });
  }, [amplifyConfig]);

  useEffect(() => {
    void (async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setStatus(AuthStatus.AUTHENTICATED);
        setUser(user as CognitoUser);
        if (appConfig.appType !== AppConfigType.SSER) {
          const isAccepted = await Api.isTosAccepted();
          if (isAccepted) {
            setBuysideTosStatus(BuysideTosStatus.TOS_ACCEPTED);
          } else {
            setBuysideTosStatus(BuysideTosStatus.TOS_NOT_ACCEPTED);
          }
        }
      } catch (e) {
        setStatus(AuthStatus.UNAUTHENTICATED);
        setBuysideTosStatus(BuysideTosStatus.TOS_NOT_ACCEPTED);
      }
    })();
  }, []);

  useEffect(() => {
    const listener: HubCallback = ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          setUser(data as CognitoUser);
          setStatus(AuthStatus.AUTHENTICATED);
          if (appConfig.appType !== AppConfigType.SSER) {
            void Api.logLegalEvent(LegalEventType.SIGN_IN);
          }
          break;
        case 'signOut':
          setUser(undefined);
          setStatus(AuthStatus.UNAUTHENTICATED);
          setBuysideTosStatus(BuysideTosStatus.TOS_NOT_ACCEPTED);
          break;
      }
    };

    Hub.listen('auth', listener);

    return () => {
      Hub.remove('auth', listener);
    };
  }, []);

  useEffect(() => {
    user?.getSession((err: Error | null, session: CognitoUserSession) => {
      if (!err) {
        const payload = session.getIdToken().decodePayload();
        setUserAttributes({
          firstName: payload[StandardCognitoAttributes.GivenName],
          lastName: payload[StandardCognitoAttributes.FamilyName],
          email: payload[StandardCognitoAttributes.Email],
        });
      }
    });
  }, [user]);

  useEffect(() => {
    if (appConfig.appType !== AppConfigType.SAHARA) {
      return () => _.noop();
    }
    const listener: HubCallback = ({ payload: { event } }) => {
      switch (event) {
        case LegalEventType.TOS_ACCEPTANCE.toString():
          setBuysideTosStatus(BuysideTosStatus.TOS_ACCEPTED);
          void Api.logLegalEvent(LegalEventType.TOS_ACCEPTANCE);
          break;
        case LegalEventType.TOS_DECLINAL.toString():
          setBuysideTosStatus(BuysideTosStatus.TOS_NOT_ACCEPTED);
          void Api.logLegalEvent(LegalEventType.TOS_DECLINAL);
          void signOut(false);
          break;
      }
    };

    Hub.listen('legal', listener);

    return () => {
      Hub.remove('legal', listener);
    };
  }, [appConfig.appType]);

  const state: AuthState = {
    status,
    tosStatus: buysideTosStatus,
    user,
    userAttributes,
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};
