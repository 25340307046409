import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import { useAuth } from '../context/auth';
import { ImpersonationContext } from '../context/impersonation';
import { User } from '../model/user';
import { useGetImpersonationUsers } from '../queries/impersonation';
import { ChildrenProps } from '../types/common';

// fixme: perhaps consider using singleton pattern
let impersonatedUserId: string | undefined = undefined;

export const getImpersonatedUserId = () => {
  return impersonatedUserId;
};

export const ImpersonationContainer = ({ children }: ChildrenProps) => {
  const [impersonatedUser, setImpersonatedUser] = useState<User | undefined>(undefined);

  const queryClient = useQueryClient();
  const { userAttributes } = useAuth();
  const { data: users } = useGetImpersonationUsers();

  const sortedUsers = useMemo(() => _.sortBy(users, ['firstName', 'lastName']), [users]);
  const loggedInUser = useMemo(
    () => _.find(users, (user) => user.email === userAttributes?.email),
    [users, userAttributes],
  );

  useEffect(() => {
    // Check if the user is exiting impersonation mode
    const isExiting = impersonatedUser?.id === undefined && impersonatedUserId !== undefined;
    // Store the current user ID outside the React state
    impersonatedUserId = impersonatedUser?.id;

    // check conditions to reset queries
    if (impersonatedUserId !== undefined || isExiting) {
      // Reset all non-impersonation queries
      void queryClient.resetQueries({
        predicate: (query) => query.queryKey[0] !== 'impersonation',
      });
    }
  }, [impersonatedUser, queryClient]);

  return (
    <ImpersonationContext.Provider
      value={{
        loggedInUser,
        users: sortedUsers,
        impersonatedUser,
        setImpersonatedUser,
        currentUser: impersonatedUser || loggedInUser,
      }}
    >
      {children}
    </ImpersonationContext.Provider>
  );
};
